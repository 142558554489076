import React, { useEffect, useState, useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import {AuthContext} from '../../../AuthProvider'

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 14%)',
        backgroundColor: '#fff',
        margin: '0 auto 30px',
        textAlign: 'center',
        width: '450px',
        maxWidth: '90%'
    },
    contenidoAsesor: {
        width: '100%', margin: '0 auto 20px',
        padding: '10px 0px 30px'
    },
    asesor: { fontWeight: 600, fontSize: '19px', margin: '15px auto 0' },
    descripcionMascota: {
        fontWeight: 300, fontSize: '10px',
        margin: '15px auto 10px', width: '90%', color: '#6B6B6B'
    },

    mascota: { width: '100%', margin: '30px auto 0' },
    cajaNutricional: { display: 'inline-block', margin: '0 10px 10px', verticalAlign: 'middle' },
    dog: { margin: '0 auto' },
    nameDog: { margin: '0 auto', fontsize: '8px', fontWeight: 'bolder', },
    cajaGris: { border: '1px solid #00AAE7', borderRadius: '5px', width: '60px', height: '60px' },
    subNutri: { fontSize: '13px', color: '#232323', fontWeight: 600, margin: '10px auto' },
    textNutri: { fontSize: '13px', color: '#232323', fontWeight: 300, margin: '10px auto' },
    btnAgregar: { color: '#00AAE7', backgroundColor: '#EFEFEF', borderRadius: '4px', width: '300px', margin: '20px auto 0' },
    textBtn: { fontSize: '14px', color: '#00AAE7', fontWeight: 500, margin: '10px auto', padding: '8px 0' }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        margin: '20px auto 0',
        color: '#00AAE7',
        borderRadius: '10px',
        width: '90%',
        fontWeight:'bolder',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
}))(Button);

const ColorButtonFormulario = withStyles((theme) => ({
    root: {

        marginBottom: '20px',
        color: '#fff',
        borderRadius: '10px',
        width: '70%',
        fontWeight:'bolder',
        backgroundColor: '#00AAE7',
        '&:hover': {
            backgroundColor: '#00AAE7',
        },
    },
}))(Button);

export default function MascotasRFF(props) {

    const {idTienda, idProducto } = props;
    const classes = useStyles();
    const {currentUser} = useContext(AuthContext)

    const [infoMascota,setInfoMascota]=useState(null)

    useEffect(async () => {

        const petsResponse = await fetch(process.env.REACT_APP_APP_PETCO_COM_MX +process.env.REACT_APP_MASCOTAS_CLIENTE_UPDATE+currentUser.idClubPetco+"/mascotas",{
            method: "GET",
                headers: {
                'Content-Type': 'application/json',
                'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
                },
            })
        
        //console.log('PetsResponse')
        //console.log(petsResponse)
    
        if(petsResponse.status!==200)
            return
    
        const jsonPetsResponse = await petsResponse.json()
        //console.log('JsonPetsResponse')
       // console.log(jsonPetsResponse)

        if(jsonPetsResponse.replyCode!==200)
            return
    
        const pets=[]
        jsonPetsResponse.data.forEach(element => {
        if(element.TIPO==='P'||element.TIPO==='G')
            pets.push(element)
        });
    
    
        console.log(pets)
        if(pets.length===0)
            return
    
        const infoMascotaResponseArray=[]
    
        const infoMascotaPromises = pets.map(async element => {

            const infoMascotaResponse = await fetch(process.env.REACT_APP_APP_PETCO_COM_MX+process.env.REACT_APP_INFO_MASCOTA,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
                },
                body:JSON.stringify({id_mascota:element.ID_MASCOTA})
            })
            return infoMascotaResponse;
        })
    
        const infoMascotaResult = await Promise.all(infoMascotaPromises)
    
        //console.log(infoMascotaResult)
    
        const infoMascotaResultFiltered = infoMascotaResult.filter(function(value){
            return value.status==200
        })
    
        //console.log(infoMascotaResultFiltered)
    
        const jsonInfoMascotaPromises= infoMascotaResultFiltered.map(async item=>{
            const jsonInfoMascotaResponse = await item.json()
            return jsonInfoMascotaResponse
        })
    
        const jsonInfoMascotaResult = await Promise.all(jsonInfoMascotaPromises)
    
    
        //console.log(jsonInfoMascotaResult)
    
        const jsonInfoMascotaResultFiltered = jsonInfoMascotaResult.filter(function(value){
            return value.data!==null&&value.replyCode===200
        })
    
        let index=0
        jsonInfoMascotaResultFiltered.forEach((item)=>{
            item.data.photoUrl=pets[index].URLMASCOTA
            infoMascotaResponseArray.push(item.data)
            index++
        })
    
        //console.log(infoMascotaResponseArray)

        const calculadoraAlimentosProductPromises = infoMascotaResponseArray.map(async (element) => {


            /*console.log({
                tienda_id: idTienda,
                cliente_id: currentUser.idClubPetco,
                mascota_id: element.id_mascota,
                material_id: idProducto
            })*/

            const calculadoraAlimentosProductResponse = await fetch(process.env.REACT_APP_APP_PETCO_COM_MX+process.env.REACT_APP_CALCULADORA_ALIMENTOS_PRODUCTO, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-pc-wss': process.env.REACT_APP_APP_PETCO_COM_MX_HEADER_VALUE
                },
                body: JSON.stringify(
                    {
                        tienda_id: idTienda,
                        cliente_id: currentUser.idClubPetco,
                        mascota_id: element.id_mascota,
                        material_id: idProducto
                    }
                )
            })
            return calculadoraAlimentosProductResponse
        })

        const calculadoraAlimentosProductResult = await Promise.all(calculadoraAlimentosProductPromises)

        //console.log(calculadoraAlimentosProductResult)

        const calculadoraAlimentosProductResultFiltered = calculadoraAlimentosProductResult.filter(function(value){
            return value.status==200
        })

        //console.log(calculadoraAlimentosProductResultFiltered)

        const jsonCalculadoraAlimentosProductPromises=calculadoraAlimentosProductResultFiltered.map(async element => {    
            const json = await element.json()
            return json;

        });

        const jsonCalculadoraAlimentosProductResult = await Promise.all(jsonCalculadoraAlimentosProductPromises)
        console.log(jsonCalculadoraAlimentosProductResult)

        const jsonCalculadoraAlimentosProductResultFiltered = jsonCalculadoraAlimentosProductResult.filter(function(value){
            return value.replyCode===200
        })

        //console.log(jsonCalculadoraAlimentosProductResultFiltered)
        if(jsonCalculadoraAlimentosProductResultFiltered.length===0){
            setInfoMascota(infoMascotaResponseArray)
            return
        }

        infoMascotaResponseArray.map((element, index)=>{
                element.recomendation=jsonCalculadoraAlimentosProductResult[index].data
        })

    
        setInfoMascota(infoMascotaResponseArray)

    }, [currentUser])



    const HandleOnClickButtonFormulario=()=>{
        window.open("https://nutricion.petco.com.mx/", "_blank")
    }



    /*if(infoMascota)
        console.log(infoMascota)*/

    if (!infoMascota)
        return (
            <section className={classes.root}>
            <article className={classes.contenidoAsesor}>
                <p className={classes.asesor}>Asesor Nutricional</p>
                <p className={classes.descripcionMascota}>No tienes mascota. Agrega ahora y completa el formulario</p>
                
                <ColorButtonFormulario > ir a formulario
                </ColorButtonFormulario>
            </article>

        </section>
        )

    return (
        <section className={classes.root}>
            <article className={classes.contenidoAsesor}>
                <p className={classes.asesor}>Asesor Nutricional</p>
                <p className={classes.descripcionMascota}>Nota: El Asesor Nutricional Petco no está diseñado para otorgar recomendaciones nutricionales en casos de gestación, lactancia o enfermedades que requieran
                    alimentos de prescripción. En caso de tener dudas, te sugerimos consultar con el médico veterinario
                    de tu preferencia.</p>
                {infoMascota.map((element, index) => {
                    return (
                        <article key={index} className={classes.mascota}>
                            <div className={classes.cajaNutricional}>
                                <Avatar className={classes.dog} alt={element.nombre_mascota} src={element.photoUrl}>
                                    {element.nombre_mascota}
                                </Avatar>
                                <div className={classes.nameDog}>{element.nombre_mascota}</div>
                            </div>
                            {
                                element.recomendation&&element.recomendation.length>0? <>
                                    <div className={classes.cajaNutricional}>
                                        <div className={classes.cajaGris}>
                                            <p className={classes.subNutri}>$/día</p>
                                            <p className={classes.textNutri}>${element.recomendation[0].analisis_costo.costo_diario}</p>
                                        </div>
                                    </div>
                                    <div className={classes.cajaNutricional}>
                                        <div className={classes.cajaGris}>
                                            <p className={classes.subNutri}>gr/día</p>
                                            <p className={classes.textNutri}>{element.recomendation[0].analisis_costo.gramos_diarios}</p>
                                        </div>
                                    </div>
                                    <div className={classes.cajaNutricional}>
                                        <div className={classes.cajaGris}>
                                            <p className={classes.subNutri}>días</p>
                                            <p className={classes.textNutri}>{element.recomendation[0].analisis_costo.duracion.split(' ')[0]}</p>
                                        </div>
                                    </div>
                                </> : <ColorButtonFormulario onClick={HandleOnClickButtonFormulario}> Llenar formulario</ColorButtonFormulario>
                            }
                        </article>
                    )
                })}
                {/*<ColorButton> + Agregar otra mascota
                </ColorButton>*/}
            </article>
        </section>
    )
}
