import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom"; 

export default function CalculadoraResult(props) {
    const history = useHistory();
    console.log(props);
    const useStyles = makeStyles((theme) => ({
        root: {
            marginTop: '20px',
            backgroundColor: '#fafafa',
            margin: '0 auto 0px',
            textAlign: 'center',
            width: '50%',
            minWidth: '300px',
            maxWidth:'450px'
        },
        resultContainer: {
            display: 'flex',
            
        },
        imgProduct: {
            width: '200px'
        },
        textoResult: {
            textAlign: 'left',
            paddingLeft: '10px',
            width: '100%',
            margin: '0'
        },
        title: {
            color: '#000',
        },
        '@media (max-width: 460px)': {
            'imgProduct': {
                width: '150px',
            },
            title: {
                fontSize: '19px'
            }
        },
        backToCalculate: {
            textAlign: 'left',
            color: '#041A4F',
            textDecoration: 'underline'
        },
        smallBack: {
            cursor: 'pointer'
        }
    }));
    const classes = useStyles();
    const data = JSON.parse(localStorage.getItem('productInstinct'));
    const back = () => {
        props.sendDataToParent(false);
    };
    if (!props)
        return (<></>);
    return (
        <div className={classes.root}>
            <p className={classes.backToCalculate}><small className={classes.smallBack} onClick={back}>&larr; Volver a calcular</small></p>
            <h2 className={classes.title}>{data.descripcion} {props.data.data.variants[0].title}</h2>
            <div className={classes.resultContainer}>
                <img className={classes.imgProduct} src={props.data.data.variants[0].src} alt={data.descripcion}></img>
                <p className={classes.textoResult}>
                    Ración Diaria:
                    <br></br>
                    <strong>{parseInt(props.data.data.calculationResults.dGrams)} gramos</strong>
                    <br></br>
                    <small>{props.data.data.calculationResults.fraction} de taza aproximadamente</small>
                </p>
            </div>
        </div>
    );
}