import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {  
    marginTop: 'auto',
    textAlign: 'right'
  },

  iconPetco: {
    width:'100px', 
    position:'relative', 
    right:'0px'
  },

  infoFoot:{  
    width:'100%', 
    margin:0, 
    textAlign:'center',
    backgroundColor: '#041A4F', 
    position: 'relative', 
    padding: '40px 0'
  },

  caja1:{margin:'0 auto 30px', width:'95%'},
  tituloFooter:{color:'#fff', margin:'0px auto', width:'90%', fontWeight: 600, fontSize: '12px'},
  textoFooter:{color:'#fff', margin:'10px auto', width:'90%', fontWeight: 100, fontSize: '11px'},
  uno:{display: 'inline-block', width:'100px', margin:'0 auto',
	verticalAlign: 'text-top'},
  tituloDescarga:{fontSize:'17px', color:'#00AAE7', fontWeight: 700},
  app:{width:'100px', float:'left'},
  dos:{display: 'inline-block', width:'50px', margin:'0 auto',
	verticalAlign: 'text-top'},
  iconFace:{width:'40px', float:'left'},
  terminos:{width:'90%', margin:'0 auto', fontSize:'11px', color:'#fff'}

}));

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      
      <footer className={classes.footer}>
        <img src="../../../../image/icon-petco.png" className={classes.iconPetco} />
        <article className={classes.infoFoot}>
          <div className={classes.caja1}>
            <p className={classes.tituloFooter}>Atención a clientes</p>
            <p className={classes.textoFooter}>Lunes a Domingo 7 am a 12 am / 800 32 73826 / info@petco.com.mx</p>
          </div>
          <div className={classes.caja1}>
            <p className={classes.tituloFooter}>Soporte para compras en línea / Seguimiento a un pedido
            </p>
            <p className={classes.textoFooter}>7 am a 12 am</p>
          </div>
          <div className={classes.caja1}>
            <article className={classes.uno}>
              <p className={classes.tituloFooter}>Grooming</p>
              <p className={classes.textoFooter}>8 am a 18 hrs </p>
            </article>
            <article className={classes.uno}>
              <p className={classes.tituloFooter}>Pedidos desde tienda física</p>
              <p className={classes.textoFooter}>9 am a 18 hrs</p>
            </article>
            <article className={classes.uno}>
              <p className={classes.tituloFooter}>Petco Easy Buy</p>
              <p className={classes.textoFooter}>24 hrs </p>
            </article>
          </div>
          <div className={classes.caja1}>
            <p className={classes.tituloDescarga}>Descarga nuestra app</p>
            <article className={classes.uno}>
              <img src="../../../../image/app-ios.png" className={classes.app}/>
            </article>
            <article className={classes.uno}>
              <img src="../../../../image/app-android.png" className={classes.app} />
            </article>
          </div>
          <div className={classes.caja1}>
            <article className={classes.dos}>
              <img src="../../../../image/instagram-v1-rt30.svg" className={classes.iconFace}/>
            </article>
            <article className={classes.dos}>
              <img src="../../../../image/facebook-v1-rt30.svg" className={classes.iconFace}/>
            </article>
            <article className={classes.dos}>
              <img src="../../../../image/twitter-v1-rt30.svg" className={classes.iconFace}/>
            </article>
          </div>
          <p className={classes.terminos}>Términos de uso. Políticas de privacidad.
            2021 <strong>PETCO</strong>, Todos los Derechos Reservados</p>
        </article>
      </footer>
    </div>
  );
}