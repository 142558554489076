import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

function EmbedVideo(props) {
    const { url } = props

    return (
        <div className="video-responsive">
            <iframe 
                width="340" 
                height="250" 
                src={decodeURIComponent(url).replace('watch?v=', 'embed/').split('&')[0]}
                title="Video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>    
            </iframe>
        </div>

    );
}

EmbedVideo.propTypes = {
    url: PropTypes.string.isRequired
};

export default EmbedVideo;