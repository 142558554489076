import { useEffect, useState } from 'react';
import './styles/instinctStyles.css';
export default function InstinctLanding(props) {

  const { store } = props;

    const [pet, setPet] = useState('dog');
    const [data, setData] = useState(null);
    const [itemSelected, setItemSelected] = useState(null);
    const [productTypeSelected, setProductType] = useState(null);

    useEffect(() => {
        // Leer el archivo JSON desde la carpeta public
        fetch('/instinct-assets/data/data.json')
          .then(response => response.json())
          .then(data => {
            setData(data);
            setItemSelected(data.find(x => x.category === 'dog'));
            setProductType(data.find(x => x.category === 'dog').productTypes[0]);
          })
          .catch(error => console.error('Error al cargar el archivo JSON:', error));
      }, []);

    const changeProduct = (productType) => {
        if (productType !== productTypeSelected.key) {
            const newSelected = itemSelected.productTypes.find(x => x.key === productType);
            setProductType(newSelected);
        }
    };

    const changePet = (petSelected) => {
        if (pet !== petSelected) {
            setPet(petSelected);
            const newItemSelected = data.find(x => x.category === petSelected);
            setItemSelected(newItemSelected);
            setProductType(newItemSelected.productTypes[0]);
        }
    };

    const bulletClick = (to, e) => {
        const parentElement = e.parentElement.parentElement.children[1];
        if (to === 'end') {
            const scroll = parentElement.scrollWidth;
            parentElement.scrollTo({ left: scroll });
            e.classList.add('active');
            e.previousElementSibling.classList.remove('active');
        } else {
            parentElement.scrollTo({ left: 0 });
            e.classList.add('active');
            e.nextElementSibling.classList.remove('active');
        }
    };

    if (!data || !itemSelected || !productTypeSelected)
        return <></>;
    return (
      <div className="contenido-completo-instinct-petco">
        <div className="seccion-header-instinct-petco">
          <img
            alt=""
            className="img-banner-desk-header-instinct"
            src="/instinct-assets/img/desk/banner-desk-header-instinct.jpg"
          ></img>
          <img
            alt=""
            className="img-banner-mobile-header-instinct"
            src="/instinct-assets/img/mobile/banner-mobile-header-instinct.jpg"
          ></img>
        </div>
        <div className="seccion-instinct-petco">
          <div className="caja-porque-instinct">
            <p className="titulo-instinct">¿Por qué Instinct?</p>
            <div className="caja-cualidades-instinct">
              <div className="columna-cualidades-instinct">
                <div className="caja-img-cualidades-instinct">
                  <img
                    className="img-cualidades-instinct"
                    alt=""
                    src="/instinct-assets/img/desk/icons-variedad.png"
                  ></img>
                </div>
                <div className="caja-titulo-cualidades-instinct">
                  <p className="titulo2-instinct">Gran variedad</p>
                </div>
                <div className="caja-texto-cualidades-instinct">
                  <p className="texto-instinct">
                    Con proteína de origen animal, sin granos, guiada por
                    nuestra creencia en la receta original, cruda y sin gluten.
                  </p>
                </div>
              </div>
              <div className="columna-cualidades-instinct">
                <div className="caja-img-cualidades-instinct">
                  <img
                    className="img-cualidades-instinct"
                    alt=""
                    src="/instinct-assets/img/desk/icons-recubierta-cruda.png"
                  ></img>
                </div>
                <div className="caja-titulo-cualidades-instinct">
                  <p className="titulo2-instinct">Alto en proteína</p>
                </div>
                <div className="caja-texto-cualidades-instinct">
                  <p className="texto-instinct">
                    77% de ingredientes de origen animal y con aceites
                    nutritivos; 23% de vegetales, frutas y otros ingredientes
                    sanos; 0% granos.
                  </p>
                </div>
              </div>
              <div className="columna-cualidades-instinct">
                <div className="caja-img-cualidades-instinct">
                  <img
                    className="img-cualidades-instinct"
                    alt=""
                    src="/instinct-assets/img/desk/icons-proteína.png"
                  ></img>
                </div>
                <div className="caja-titulo-cualidades-instinct">
                  <p className="titulo2-instinct">Recubierta cruda</p>
                </div>
                <div className="caja2-texto-cualidades-instinct">
                  <p className="texto-instinct">
                    La única croqueta en México con una recubierta de carne
                    cruda pura y real, carne cruda en cada bocado.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seccion-categorias-instinct-petco">
          <div className="caja-categorias-instinct">
            <div className="caja-btn-categorias-instinct">
              <button onClick={() => changePet('dog')}
                className={`btn-categoria-instinct ${
                  pet === "dog" ? "active" : ""
                }`}
              >
                Para Perro
              </button>
              <button onClick={() => changePet('cat')}
                className={`btn-categoria-instinct ${
                  pet === "cat" ? "active" : ""
                }`}
              >
                Para Gato
              </button>
            </div>
            <div className="caja-subcategorias-instinct">
              <p className="titulo3-instinct">
                Tenemos diferentes tipos de alimento para consentir a tu mascota
              </p>
              <div className="caja-btn-subcategorias-instinct">
                {itemSelected.productTypes.map((item, index) => (
                  <button onClick={() => changeProduct(item.key)}
                    key={index}
                    className={`btn-subcategoria-instinct ${
                      productTypeSelected.key === item.key ? "active" : ""
                    }`}
                  >
                    <img
                      className="img-bsubcategoria-instinct"
                      alt={item.name}
                      src={item.image}
                    ></img>
                    <p className="subtitulo-subcategoria-instinct">
                      {item.name}
                    </p>
                  </button>
                ))}
              </div>
              <div className="caja-bullets-categorias-instinct">
                <span className="bullets-categorias-instinct active" onClick={(e) => bulletClick('start', e.target)}></span>
                <span className="bullets-categorias-instinct" onClick={(e) => bulletClick('end', e.target)}></span>
              </div>
            </div>
          </div>
          <div className="caja-productos-subcategoria-instinct">
            <div className="contenedor-info-subcategoria6-instinct">
              <div className="contenedor-texto-subcategorias6-instinct">
                <div className="caja-texto-subcategorias-instinct">
                  <p className="titulo-subcategoria-instinct">
                    {productTypeSelected.infoSection.title}
                  </p>
                  <div className="caja-list-subcategorias-instinct">
                    {productTypeSelected.infoSection.list.map((item, index) => (
                      <div key={index} className="punto-lista-subcategoria-instinct">
                        <img
                          className="icon-list-subcategorias-instinct"
                          alt={productTypeSelected.infoSection.title}
                          src="/instinct-assets/img/desk/icon-list.svg"
                        ></img>
                        <p className="texto-list-subcategoria-instinct">
                          {item.text}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="contenedor-img-subcategorias-instinct">
                  <img
                    className="img-benatural-cat-instinct"
                    alt={productTypeSelected.infoSection.name}
                    src={productTypeSelected.infoSection.image}
                  ></img>
                </div>
              </div>
            </div>
            <div className="contenedor-productos-subcategorias-instinct">
              <p className="titulo-instinct">Nuestros productos</p>
              <div className={`${productTypeSelected.css.productsContainer} ${productTypeSelected.css.scroll ? 'h-scroll' : ''}`}>
                {productTypeSelected.products.map((item, index) => (
                  item.link ? (
                    <div key={index} className={`${productTypeSelected.css.productdiv}`}>
                    <img
                      className="img-producto-instinct"
                      alt={item.name}
                      src={item.image}
                    ></img>
                    <p className="subtitulo-producto-instinct">
                      {item.brand} <br />
                      {item.name} <br />
                      {item.description}
                    </p>
                    <a className="btn-producto-instinct" href={`https://qr.petco.com.mx/lps/t/${store}${item.link}`}>
                      ver producto
                    </a>
                  </div>
                  ) : <></>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="seccion-banner-comprar-instinct-petco">
          <div className="columna1-banner-comprar-instinct">
            <img
              className="img-banner-comprar-instinct"
              alt={productTypeSelected.name}
              src={itemSelected.footer.deskImage}
            ></img>
            <img
              className="img-banner-mobile-comprar-instinct"
              alt={productTypeSelected.name}
              src={itemSelected.footer.deskImage}
            ></img>
          </div>
          <div className="columna2-banner-comprar-instinct">
            <p className="titulo4-instinct">Transforma su vida</p>
            <p className="texto2-instinct">
              Instinct® satisface las necesidades nutricionales instintivas de
              tu mascota con una nutrición 100% libre de granos y gluten que
              resulta en una salud vibrante que se nota a simple vista.
            </p>
            <button className="btn-comprar-instinct">Comprar Ahora</button>
          </div>
        </div>
      </div>
    );    
}