import InstinctLanding from "./components/instinct/instinctLanding";
import { Redirect } from 'react-router-dom';


export function BrandLanding(props) {
    const { brand, idTienda } = props;
    if (!brand)
        return (<></>);

    switch(brand.toLowerCase()) {
        case 'instinct':
            return <InstinctLanding store={idTienda}/>;
        default:
            return <Redirect to="/home"/>
    }
}